<template>
    <auth-wrapper class="font-app">
        <div class="text-center mb-5">
            <img
                @click="$router.push({ name: 'Home' })"
                class="auth-logo"
                alt="logo"
                src="@/assets/logo.png"
            />
        </div>
        <div v-if="emailSent" class="text-center">
            <h2 class="space-bottom-1">Check your inbox</h2>
            <div class="mb-5 px-lg-2 space-top-1 space-bottom-2">
                Click the link we sent to
                <div class="h3 my-1">{{ email }}</div>
                to sign in and reset your password.
            </div>
        </div>
        <div v-else>
            <div class="text-center mb-4">
                <div class="h2">What's your email?</div>
                <div class="lead">
                    If your email is associated with an account, we'll lead you
                    through resetting your password.
                </div>
            </div>

            <error-card
                class="mb-4"
                :class="{ shake: animated }"
                :errors="errors"
            />
            <validation-observer ref="validator">
                <text-input
                    v-model="email"
                    validation-rules="email|required"
                    placeholder="Email"
                />
            </validation-observer>

            <div class="w-100 mb-4" />

            <div class="text-center pt-1">
                <div class="mb-5">
                    <div class="d-flex justify-content-end">
                        <btn
                            app
                            block
                            @click="dispatchResetPassword"
                            :loading="loading"
                        >
                            Next
                        </btn>
                    </div>
                </div>

                Change your mind?
                <a href="javascript:;" @click="$router.push({ name: 'Login' })">
                    Go back to login
                </a>
            </div>
        </div>
    </auth-wrapper>
</template>

<script>
import authWrapper from './auth-wrapper';

export default {
    components: {
        authWrapper,
    },
    data() {
        return {
            email: this.$route.query.email,
            errors: [],
            animated: false,
            emailSent: false,
            loading: false,
        };
    },
    props: {
        source: String,
    },
    methods: {
        clickHandler() {
            const self = this;
            self.animated = true;
            setTimeout(() => {
                self.animated = false;
            }, 1000);
        },
        async dispatchResetPassword() {
            await this.$refs.validator.validate();

            if (!this.$refs.validator.flags.valid) return;

            this.loading = true;

            try {
                var response = await this.$store.dispatch(
                    'sendPasswordReset',
                    this.email,
                );

                this.emailSent = true;
            } catch (e) {
                if (this.errors.length === 0) {
                    this.errors.push(e.response.data);
                } else {
                    this.clickHandler();
                }
            }

            this.loading = false;
        },
    },
};
</script>
